<template>
  <div class="pa-3">
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex flex-column">
        <h2 v-text="internalName"></h2>
        <p class="my-0">Hoy es <strong v-text="dateCurrentFormat"></strong></p>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex justify-center">
        <v-img
          :src="internalImageUrl"
          @error="isErrorOnLoadInternalImage = true"
        />
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3">
        <academic-event-day
          v-for="(date, key, i) in eventDays"
          :eventDay="date"
          :day="key"
          :key="i"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AcademicEventDay from "./components/AcademicEventDay";
import { BASE_IMAGES_URL } from "@/constants/ServicesConstants";
import loaderMixin from "@/mixins/loaderMixin";
import notificationMixin from "@/mixins/notificationMixin";
import moment from "moment";

export default {
  components: { AcademicEventDay },
  name: "AcademicEvent",
  mixins: [loaderMixin, notificationMixin],
  data() {
    return {
      eventDays: {},
      internalImageUrl: null,
      isErrorOnLoadInternalImage: false,
    };
  },
  methods: {
    redirectToFair() {
      this.$router.push({ name: "fair" });
    },
    getActivities() {
      this.$axiosApiMobile
        .get(`/academicActivities?fairId=${this.fair.id}`)
        .then((res) => {
          this.eventDays = {};
          res.data.forEach((item) => {
            if (!this.eventDays[item.date]) {
              this.eventDays[item.date] = [];
            }
            this.eventDays[item.date].push(item);
          });
          this.hideLoader();
        })
        .catch((error) => {
          this.errorHandler(error?.response?.status);
          if (!this.$store.getters["offline/isOnline"]) {
            this.$route.push({ name: "offline" });
          }
          this.hideLoader();
        });
    },
    getInternalImageUrl() {
      this.$axiosApiMobile
        .get(`/fairs/${this.fair.id}/appModules/EVA/internalImageUrl`)
        .then((res) => {
          if (res.data) {
            this.internalImageUrl = `${BASE_IMAGES_URL}${res.data}`;
          }
        })
        .catch((error) => {
          this.errorHandler(error?.response?.status);
        });
    },
  },
  computed: {
    fair() {
      return this.$store.getters["fairs/fair"];
    },
    dateCurrentFormat() {
      return moment().format("dddd, D ## MMMM").replace("##", "de");
    },
    internalName() {
      return (
        this.fair?.app_modules?.filter((item) => item.modules == "EVA")[0]
          ?.internalName ?? "Evento Acádemico"
      );
    },
  },
  created() {
    moment.locale("es");
    this.getActivities();
    this.getInternalImageUrl();
    this.showLoader();
  },
};
</script>
